.fa-input {
  position: relative;
}

.fa-input i {
  position: absolute;
  top: 0.375rem;
  right: 1.375rem;
  color: #8a9098;
}

.text-smartstream {
  color: #2ca961;
}

.params {
  font-size: 0.9rem;
}

.owner-info {
  min-width: 12rem;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.stats thead th {
  border-top: none;
  vertical-align: middle;
}

div.calendar {
  position: absolute;
  left: 50%;
  margin-top: 0.1rem;
  transform: translate(-50%, 0%);
  box-shadow: 0 0 5px #999c9f;
}
